import Ofrecemos from '../components/home/Ofrecemos';
import Review from '../components/home/Review';
import Steps from '../components/home/Steps';
import Contactos from '../components/home/contactanos';
import Faqs from '../components/home/FAQ';
import QuoteForm from '../components/home/FormQuote';
import Home from '../components/home/Home';
import Info from '../components/home/Info';
import Banner from '../components/home/Banner';

export default function HomePage() {
    return (
        <>
            <Home />
            <div className='d-block d-md-none px-4 mt-5' id='form-section-quote'>
                <QuoteForm />
            </div>
            <Info />
            <Ofrecemos />
            <Steps />
            <Review />
            <Faqs />
            <Contactos />
            <Banner />  
        </>
    );
}