import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
  const navigate = useNavigate();

  const customNavigate = (target) => {
    const isHomePage = window.location.pathname === '/';
    if (!isHomePage) {
      navigate('/');
      setTimeout(() => { // Wait for navigation to complete
        document.querySelector(target)?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      document.querySelector(target)?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return customNavigate;
};
