import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navi from './components/common/Nav';
import Footer from './components/common/Footer';
import GraciasPage from './pages/GraciasPage';
import HomePage from './pages/HomePage';
import TermsConditionsPage from './pages/TermsConditionsPage';
import InformacionPage from './pages/InformacionPage';
import BlogPage from './pages/BlogPage';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_12_zymadx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Router>
      <div className="App">
        <Navi />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path='/info' element={<InformacionPage />} />
          <Route path='/terms' element={<TermsConditionsPage />} />
          <Route path='/gracias' element={<GraciasPage />} />
          <Route path="/blog/*" element={ <BlogPage /> } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
