import React, { useState } from 'react';
import { Row, Col, Accordion, Carousel, } from 'react-bootstrap';
import '../../step.css'

const Steps = () => {

  const [activeKey, setActiveKey] = useState("0");


  return (
    <div className='my-5 py-5 g-0'>
      <Row className='g-0'>
        <Col md={6} xs={12}>
          <h1 className='px-5 mx-5'>Envía tu vehículo de esta manera</h1>
          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75" >
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>1. Realiza la cotización</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#F7FAEA"}} >
              Solicita la cotizacion online o contactanos al 1-870-455-6742 para hacerte un estimado
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>2. Paga la reserva</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#F7FAEA"}}>
              Una vez apruebes tu estimado debes pagar tu reserva
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>3. Recibe tu auto en el destino</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#F7FAEA"}}>
              Esperas a recibir tu carro en el destino y pagas el total del servicio cotizado previamente
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </Col>

        <Col md={6} xs={12} className='g-0'>
          <Carousel className='g-0' data-bs-theme="dark">
            <Carousel.Item interval={1000}  >
              <img
                className="d-block w-100"
                src="/images/step1.png"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1000} >
              <img
                className="d-block w-100"
                src="/images/step2.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1000} >
              <img
                className="d-block w-100"
                src="/images/step3.png"
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default Steps;
