import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import PostInfoTitlePrimary from './info/PostInfoTitlePrimary.jsx';
import PostInfoTitleSecondary from './info/PostInfoTitleSecondary.jsx';
import PostInfoListPoint from './info/PostInfoListPoint.jsx';
import PostInfoListNumber from './info/PostInfoListNumber.jsx';
import PostInfoParagraph from './info/PostInfoParagraph.jsx';
import PostInfoImage from './info/PostInfoImage.jsx';

const PostInfo = ({ details }) => {

  const json = details;

  return (
    <>
      {renderDetail({ json })}
    </>
  );
};
export default PostInfo;

PostInfo.propTypes = {
  details: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      contentStyleType: propTypes.oneOf(['primary', 'secondary', 'hr']).isRequired,
      content: propTypes.shape(
        {
          title: propTypes.string.isRequired,
          bodies: propTypes.arrayOf(
            propTypes.shape({
              body_type: propTypes.oneOf(['paragraph', 'img', 'list_points', 'list_number', 'list_number_sub_content']).isRequired,
              body: propTypes.arrayOf(
                {
                  id: propTypes.number.isRequired,
                  text: propTypes.string,
                  sub_text: propTypes.arrayOf(
                    {
                      id: propTypes.number.isRequired,
                      text: propTypes.string.isRequired
                    }
                  ),
                  path: propTypes.string,
                  alt: propTypes.string,
                  width: propTypes.string,
                  height: propTypes.string
                }
              ).isRequired
            })
          )
        }
      ),
    }).isRequired
  ).isRequired
}

const renderDetail = ({ json }) => json.map(item => {
  const content = item?.content; // access content from the current item
  const contentStyleType = item?.contentStyleType; // access contentStyleType from the current item
  const id = item.id; // access id from the current item
  return (
    <Fragment key={id}>
      {contentStyleType === 'primary' && <PostInfoTitlePrimary title={content?.title} />}
      {contentStyleType === 'secondary' && <PostInfoTitleSecondary title={content?.title} />}
      {contentStyleType === 'hr' && <hr className="divider-solid" />}
      {renderBodies({ content })} {/* pass content to renderBodies */}
    </Fragment>
  );
});

const renderBodies = ({ content }) => {
  if (!content?.bodies) return null; 
  return content.bodies.map(({ body_type: type, ...body }) => {
    const Component = {
      paragraph: PostInfoParagraph,
      img: PostInfoImage,
      list_points: PostInfoListPoint,
      list_number_sub_content: PostInfoListNumber
    }[type];

    return Component ? <Component key={body.id} {...body} /> : null;
  });
};