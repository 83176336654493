import propTypes from 'prop-types';
import '../../Blog.css';

/**
 * A function component that takes a title string
 * and renders an <h2> element with the title as its content.
 *
 * The purpose of this component is to render a
 * secondary title for a blog post. The component
 * uses the class "post-title-h2-primary" to style the
 * <h2> element.
 *
 * @param {{ title: string }} props
 */
const PostInfoTitleSecondary = ({ title }) => {
    return (
        <h2 className="post-title-h2-primary">
            {title}
        </h2>
    );
};

export default PostInfoTitleSecondary;

PostInfoTitleSecondary.propTypes = {
    title: propTypes.string.isRequired
}