import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useCallback, useMemo, useState } from "react";
import PropTypes from 'prop-types';

 function useFetchPosts() {
   return useQuery({
        queryKey: ['posts'], // clave de consulta
        queryFn: async () => {
            const response = await fetch(`${window.location.origin}/post/post.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                cache: 'no-store',
            });

            if (!response.ok) throw new Error('No se encontraron datos');

            const postsData = await response.json();
            const postDetailsPromises = postsData.map((post) =>
                post.ref
                    ? fetch(`${window.location.origin}/${post.ref}`).then((res) => res.json())
                    : Promise.resolve({})
            );

            const postDetails = await Promise.all(postDetailsPromises);
            return postsData.map((post, i) => ({
                ...post,
                details: postDetails[i],
                date: moment(post.date, 'YYYY-MM-DD'),
            }));
        }, // función para recuperar datos
        staleTime: Infinity, // tiempo en que los datos son considerados frescos
        // cacheTime: 60000, // tiempo que los datos permanecerán en caché
    });
};

const blogContext = createContext();

export const BlogProvider = ({ children }) => {
    const { data, error, loading } = useFetchPosts();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        if (data && !error && !loading) {
            const postsWithDate = data.map((post) => ({
                ...post,
                date: moment(post.date, 'YYYY-MM-DD'),
            }));

            const postsWithDateSorted = postsWithDate.sort((a, b) => b.date - a.date);
            setPosts(postsWithDateSorted);
        }
    }, [data, error, loading]);

    const findById = useCallback((postId) => {
        const post = posts.find((post) => post.id === postId);
        return post;
    }, [posts]);

    const value = useMemo(() => ({
        data,
        error,
        loading,
        findById,
        posts,
    }), [data, error, loading, findById, posts]);

    return (
        <blogContext.Provider value={value}>
            {children}
        </blogContext.Provider>
    );
}

BlogProvider.propTypes = {
    children: PropTypes.node
}

export const useBlogContext = () => {
    return useContext(blogContext);
}