import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Review = () => {
  return (
    <div fluid style={{ backgroundColor: '#F7FAEA', padding: '20px' }}>
      <h1 className='color-review mt-3' style={{ textAlign: 'center', marginBottom: '20px' }}>Nuestros clientes dicen</h1>
      <Row className="g-0">
        <Col md={4} xs={12} className="text-center my-3">
          <img
            src="/images/review1.png"
            alt="Carly Garcia"
            className="img-fluid mb-3"
          />
          <h2 className='color-review'>Carly Garcia</h2>
          <p className='color-review texto-review px-4 text-center'>Estoy tan feliz con el servicio!! Recogieron mi auto en la puerta de la casa ayer y hoy ya las recibió mi hija todo en perfecto estado. Constantemente sé están comunicando informándote de todo el trayecto y siempre reciben llamadas. Con seguridad sé los recomiendo!!!</p>
        </Col>
        <Col md={4} xs={12} className="text-center my-3">
          <img
            src="/images/review2.png"
            alt="Jesus Cuevas"
            className="img-fluid mb-3"
          />
          <h2 className='color-review'>Jesus Cuevas</h2>
          <p className='color-review texto-review px-4 text-center'>Muy buen servicio, rápido y seguro, solo tuve que dar un pequeño anticipo para reservar el transporte y que recogieran mi carro y el excedente lo pagué cuando me lo entregaron a satisfacción en el destino. Recomiendo 100% esta compañía.</p>
        </Col>
        <Col md={4} xs={12} className="text-center my-3">
          <img
            src="/images/review3.png"
            alt="Santiago martinez"
            className="img-fluid mb-3"
          />
          <h2 className='color-review'>Santiago Martinez</h2>
          <p className='color-review texto-review px-4 text-center'>Elegir a No Manejes para el envío de mi vehículo fue la mejor decisión. Desde la consulta inicial hasta la entrega final, su equipo demostró profesionalismo y eficiencia. Recomiendo a No Manejes para cualquiera que necesite servicios confiables de transporte de vehículos.</p>
        </Col>
      </Row>
    </div>
  );
};

export default Review;