import { Col, Row } from "react-bootstrap"


export default function Banner() {
    return (
        <section id="banner" fluid style={{ padding: '20px', paddingBlock: '80px' }} className="h-100">
            <Row fluid className="g-0 justify-content-between px-4 h-100">
                <div className='d-flex justify-content-center justify-content-lg-start mb-4'>
                    <img src="/images/banner/garlasllc-banner-tittle.svg" alt="Garlas LLC" height="30" width="auto" className='align-self-start' />
                </div>

                <Col lg={5} sm={12} className='d-flex flex-column gap-3 justify-content-between box-border pe-lg-4' >
                    <h3 className='banner-garlas-llc-heading text-font text-center text-lg-start'>
                        Tu aliado más grande en los <wbr /> Estados Unidos para transportar <wbr /> tu vehiculo
                    </h3>
                    <div className='d-flex flex-column justify-content-between rounded-4 py-2 px-3 bg-pink w-100 gap-3 h-100'>
                        <p className='banner-garlas-llc-card-paragraph text-font' >Somos una red de empresas <wbr /> especializadas en el transporte de tu <wbr /> auto, moto, camión, bote, yate etc.</p>
                        <p className='banner-garlas-llc-card-paragraph-2 d-flex justify-content-center justify-content-lg-end text-font'>Tu dinos!</p>
                    </div>
                </Col>
                <Col lg={7} sm={0} className="d-none d-lg-flex mh-100">
                    <BannerLogos />
                </Col>
                <Col lg={0} md={12} className="d-flex d-lg-none w-100 overflow-hidden">
                    <BannerLogosMobile />
                </Col>
            </Row>
        </section>
    )
}

const logos = {
    logo_nomanejes: { src: "/images/banner/logo_nomanejes.svg", alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: "/images/banner/logo_puertoricocartransfer.svg", alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: "/images/banner/logo_carrosacentroamerica.svg", alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: "/images/banner/logo_prautotransport.svg", alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: "/images/banner/logo_carmoversus.svg", alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: "/images/banner/logo_shipshippersusa.svg", alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: "/images/banner/logo_ratesautotransport.svg", alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: "/images/banner/logo_tucarroporusa.svg", alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: "/images/banner/logo_tcpamerica.svg", alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: "/images/banner/logo_ally_centraldispatch.svg", alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: "/images/banner/logo_ally_seaboardmarine.svg", alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: "/images/banner/logo_ally_trailerbridge.svg", alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: "/images/banner/logo_ally_crowley.svg", alt: "Logo Crowley" },
    logo_interport: { src: "/images/banner/logo_ally_interport.svg", alt: "Logo Interport" },
    logo_cmacgm: { src: "/images/banner/logo_ally_cmacgm.svg", alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: "/images/banner/logo_payment_visa.svg", alt: "Logo Visa" },
    logo_mastercard: { src: "/images/banner/logo_payment_mastercard.svg", alt: "Logo Mastercard" },
    logo_americanexpress: { src: "/images/banner/logo_payment_americanexpress.svg", alt: "Logo Paypal" },
}

function BannerLogos() {
    return (
        <div fluid className="d-flex flex-column h-100 justify-content-between">
            <div className="d-flex justify-content-between w-100 ">
                <img src={logos.logo_nomanejes.src} alt={logos.logo_nomanejes.alt} style={{ width: '18%' }} />
                <img src={logos.logo_puerto_rico_car_transfer.src} alt={logos.logo_puerto_rico_car_transfer.alt} style={{ width: '18%' }} />
                <img src={logos.logo_carro_a_centro_america.src} alt={logos.logo_carro_a_centro_america.alt} style={{ width: '14%' }} />
                <img src={logos.logo_pr_autotransport.src} alt={logos.logo_pr_autotransport.alt} style={{ width: '12%' }} />
                <img src={logos.logo_carmoversus.src} alt={logos.logo_carmoversus.alt} style={{ width: '10%' }} />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 ">
                <img src={logos.logo_shipshippersusa.src} alt={logos.logo_shipshippersusa.alt} style={{ width: '34%' }} />
                <img src={logos.logo_ratesautotransport.src} alt={logos.logo_ratesautotransport.alt} style={{ width: '20%' }} />
                <img src={logos.logo_tucarroporusa.src} alt={logos.logo_tucarroporusa.alt} style={{ width: '12%' }} />
                <img src={logos.logo_tcpamerica.src} alt={logos.logo_tcpamerica.alt} style={{ width: '22%' }} />
            </div>

            <div className="d-flex justify-content-between w-100 ">
                <img src={logos_ally.logo_ally_centraldispatch.src} width={"26%"} alt={logos_ally.logo_ally_centraldispatch.alt} />
                <img src={logos_ally.logo_ally_seaboard.src} width={"14%"} alt={logos_ally.logo_ally_seaboard.alt} />
                <img src={logos_ally.logo_ally_trailerbridge.src} width={"18%"} alt={logos_ally.logo_ally_trailerbridge.alt} />
                <img src={logos_ally.logo_crowley.src} width={"16%"} alt={logos_ally.logo_crowley.alt} />
                <img src={logos_ally.logo_interport.src} width={"14%"} alt={logos_ally.logo_interport.alt} />
            </div>

            <div className="d-flex justify-content-between align-items-center w-100 ">
                <img src={logos_ally.logo_cmacgm.src} width={"100px"} alt={logos_ally.logo_cmacgm.alt} />
            </div>

            <div className="d-flex justify-content-between align-items-center gap-2 w-100 ">
                <div className="d-flex gap-2 justify-content-center gap-1">
                    <img src={logos_payments.logo_visa.src} height={"18px"} alt={logos_payments.logo_visa.alt} />
                    <img src={logos_payments.logo_mastercard.src} height={"26px"} alt={logos_payments.logo_mastercard.alt} />
                    <img src={logos_payments.logo_americanexpress.src} height={"24px"} alt={logos_payments.logo_americanexpress.alt} />
                </div>
                <p className="text-center text-font" style={{ fontSize: "18px", fontWeight: 400, lineHeight: "24px" }}>Comunícate con alguna de nuestras empresas HOY.</p>
            </div>

        </div>
    )
}

function BannerLogosMobile() {
    return (
        <div className="d-flex flex-column gap-5 w-100 container py-5">
            <div className="logo-slider overflow-hidden position-relative mb-2">
                <div className="logo-track d-inline-block" id="logo-container">
                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }

                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                </div>
            </div>
            <div className="logo-slider overflow-hidden position-relative mb-3">
                <div className="logo-track d-inline-block" id="logo-ally-container">
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                </div>
            </div>
            <div className="d-flex gap-3 justify-lg-content-between justify-content-center align-items-center">
                <img src={logos_payments.logo_visa.src} alt="Visa" height={"20"} />
                <img src={logos_payments.logo_mastercard.src} alt="Mastercard" height={"34"} />
                <img src={logos_payments.logo_americanexpress.src} alt="American Express" height={"30"} />
            </div>
            <div className="text-center">
                <p className="fs-5 text-font">Comunícate con alguna de nuestras empresas HOY.</p>
            </div>
        </div>
    );
}