import propTypes from 'prop-types';
import { useCallback } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';

const BlogCarousel = ({ posts, size, justify, }) => {
    const navigate = useNavigate();
    const handlePostClick = useCallback((postId) => navigate(`/blog/post/${postId}`), [navigate]);
    const heightSize = {
        small: "270px",
        medium: "370px",
        large: "550px",
    }
    const justifyContent = {
        start: "justify-content-start",
        center: "justify-content-center",
        end: "justify-content-end",
    }

    return (
        <Row
            id="blogRow"
            lg={3}
            className={`align-content-center mx-lg-0 px-lg-0 px-5 my-1 my-lg-0 d-flex gap-3 gap-lg-2  ${justifyContent[justify]}`}

        >
            {posts.map(({ id, title, cardImgPath, resume }) => (
                <Col
                    id="blogCol"
                    key={id}
                >
                    <Card
                        id="blogCard"
                        className='w-100 rounded-4 bg-card-secondary border-0'
                        onClick={() => handlePostClick(id)}
                        style={{ height: heightSize[size] }}
                    >
                        <div className='p-2 bg-card-primary rounded-top-4'>
                            <Card.Img
                                variant="top"
                                src={cardImgPath.path}
                                alt={cardImgPath.alt}
                                className="w-100 h-100"
                            />
                        </div>
                        <Card.Title className='px-4 rounded-bottom-4 bg-card-primary'>{title}</Card.Title>
                        <Card.Body
                            className='text-font py-0'
                        >
                            {resume &&
                                <Card.Text className="pb-3 p-lg-auto">
                                    {resume}
                                </Card.Text>}
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default BlogCarousel;

BlogCarousel.propTypes = {
    size: propTypes.oneOf(['small', 'medium', 'large']),
    length: propTypes.number.isRequired,
    justify: propTypes.oneOf(['start', 'center', 'end']).isRequired,
    posts: propTypes.arrayOf(propTypes.shape({}))
}
