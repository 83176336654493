import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const QuoteForm = () => {
    const navigate = useNavigate();

    const envioDesdeRef = useRef(null);
    const hastaRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3; // Adjust based on your form parts

    const [formData, setFormData] = useState({
        vehiculo: '',
        envioDesde: '',
        hasta: '',
        nombreApellido: '',
        email: '',
        telefono: '',
    });

    const [errors, setErrors] = useState({
        vehiculo: true,
        envioDesde: true,
        hasta: true,
        nombreApellido: true,
        email: true,
        telefono: true,
    });

    const [touched, setTouched] = useState({
        vehiculo: false,
        envioDesde: false,
        hasta: false,
        nombreApellido: false,
        email: false,
        telefono: false,
    });

    // Similar to your existing useEffect for Google Places
    useEffect(() => {
        if (!window.google) return;
    
        initAutocomplete(envioDesdeRef.current, 'envioDesde');
        initAutocomplete(hastaRef.current, 'hasta');
    }, []);

    // Existing functions like initAutocomplete, validateField, handleChange remain the same
    const initAutocomplete = (element, fieldName) => {
        if (!element) return; // Guard clause if the element is not yet available
    
        const autocomplete = new window.google.maps.places.Autocomplete(element, { types: ['geocode'], componentRestrictions: { country: ['us', 'pr', 'gt', 'sv', 'ni', 'hn'] } });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                // Inform the user that a valid place was not selected
                // Set both error and touched state to true for fieldName
                setErrors(prev => ({ ...prev, [fieldName]: true }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            } else {
                // If a valid place is selected, update formData, and clear any error for fieldName
                // Also, mark fieldName as touched
                setFormData(prev => ({ ...prev, [fieldName]: place.formatted_address }));
                setErrors(prev => ({ ...prev, [fieldName]: false }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            }
        });
    };


    const validateField = (name, value) => {
        const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}-?\d{0,4}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (name) {
            case 'vehiculo':
                return value.trim() !== '';
            case 'nombreApellido':
                return value.trim() !== '';
            case 'telefono':
                return phoneRegex.test(value);
            case 'email':
                return emailRegex.test(value);
            default:
                return false;
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        let isValid = true;

    if (name === "telefono") {
        // Elimina todos los caracteres que no sean dígitos
        
        const digits = value.replace(/\D/g, "");

        // Limita la cantidad de dígitos a 15, permitiendo hasta 4 caracteres adicionales por los guiones
        const limitedDigits = digits.slice(0, 15);

        let formattedNumber = '';

        // Formatea el número con paréntesis y guiones
        if (limitedDigits.length <= 3) {
            formattedNumber = `(${limitedDigits}`;
        } else if (limitedDigits.length <= 6) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3)}`;
        } else if (limitedDigits.length <= 10) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
        } else {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}-${limitedDigits.slice(10)}`;
        }
        // Limita la longitud total del campo a 16 caracteres (paréntesis, guiones y dígitos)
        formattedNumber = formattedNumber.substring(0, 19);

        isValid = validateField(name, formattedNumber);
        setFormData(prevData => ({ ...prevData, [name]: formattedNumber }));
    } else {
        isValid = validateField(name, value);
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }

    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
    };
    

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const isCurrentStepValid = () => {
        // This will validate the fields of the current step
        switch (currentStep) {
            case 1:
                return !errors.envioDesde && !errors.hasta;
            case 2:
                return !errors.vehiculo && !errors.nombreApellido;
            case 3:
                return !errors.email && !errors.telefono;
            default:
                return false;
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
    
        const { vehiculo, envioDesde, hasta, nombreApellido, email, telefono } = formData;
        try {
            const options = {
                method: 'POST',
                // proxy: {
                //     protocol: 'http',
                //     host: '104.239.107.201',
                //     port: 5853
                // },
                headers: {
                    'Content-Type': 'application/json',
                    'cookie': 'qmb=0.', // Include if needed, otherwise remove
                },
                body: JSON.stringify({
                    fields: {
                        TITLE: nombreApellido,
                        EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                        PHONE: [{ VALUE: telefono, VALUE_TYPE: 'WORK' }],
                        UF_CRM_1470686551: envioDesde,
                        UF_CRM_1470686597: hasta,
                        ASSIGNED_BY_ID: "2", // Jose Garavito ID
                        SOURCE_ID: "Solicitud de cotización NoManejes",
                        UF_CRM_1486142337: vehiculo,
                    }
                }),
            };
    
            const response = await fetch('https://carmoversus.com/api/v1/lead', options);
            if (!response.ok) throw new Error('Network response was not ok');
//            const data = await response.json();

            navigate('/gracias');
            // Reset form data, errors, and touched states
            setFormData({
                vehiculo: '',
                envioDesde: '',
                hasta: '',
                nombreApellido: '',
                email: '',
                telefono: '',
            });

            setErrors({
                vehiculo: true,
                envioDesde: true,
                hasta: true,
                nombreApellido: true,
                email: true,
                telefono: true,
            });

            setTouched({
                vehiculo: false,
                envioDesde: false,
                hasta: false,
                nombreApellido: false,
                email: false,
                telefono: false,
            });
        } catch (error) {
            console.error("There was an error adding the lead:", error);
        }
    };

    const StepIndicator = () => (
        <div className="step-indicator my-3">
          {['Destino', 'Vehículo', 'Contacto'].map((label, index) => (
            <React.Fragment key={label}>
              <div
                className={`step-bubble mx-auto d-none d-md-block ${currentStep === index + 1 ? 'active' : ''} ${currentStep > index + 1 ? 'completed' : ''}`}
              >
                {index + 1} {label}
              </div>
              {index < 2 && <span className="step-arrow  d-none d-md-block ">{'>'}</span>}
            </React.Fragment>
          ))}
        </div>
      );


    
    return (
    <Card className="px-5 my-3 rounded-4" > {/* Moved padding inside the card */}
        <h1 className='mt-5 text-center'>Cotiza tú envio</h1>
        <StepIndicator />

        <form method="post" onSubmit={handleSubmit} className='mb-5'>
            {currentStep === 1 && (
                // Step 1: Envio Desde and Hasta
                <div>
                    <div className="form-group mb-2">
                        <label className=' fw-medium mb-2 ' htmlFor='envioDesde'>Origen</label>

                        <input
                            className={`form-control mb-2 ${errors.envioDesde && touched.envioDesde ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Ej: 1111 Franklin Avenue, Garden City, Nueva York, EE. UU."
                            ref={envioDesdeRef}
                            // onFocus marks the field as touched, assuming user interaction begins
                            onFocus={() => setTouched({ ...touched, envioDesde: true })}
                        />
                        {errors.envioDesde && touched.envioDesde && <div className="invalid-feedback">Por favor, seleccione un lugar válido.</div>}
                    </div>

                    {/* Envio hasta - Google Places Autocomplete */}
                    <div className="mb-2 form-group">
                        <label className=' fw-medium mb-2 ' htmlFor='hasta'>Destino</label>
                        <input
                            className={`form-control mb-2 ${errors.hasta && touched.hasta ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Ej: 555 California Street, San Francisco, California, EE. UU."
                            ref={hastaRef}
                            // onFocus marks the field as touched, assuming user interaction begins
                            onFocus={() => setTouched({ ...touched, hasta: true })}
                        />
                        {errors.hasta && touched.hasta && <div className="invalid-feedback">Por favor, seleccione un lugar válido.</div>}
                    </div>
                </div>
            )}

            {currentStep === 2 && (
                // Step 2: Vehiculo and Nombre y Apellido
                <div>
                     {/* Vehiculo */}
                    <div className="mb-2 form-group">
                        <label className=' fw-medium mb-2 ' htmlFor='hasta'>Marca, Modelo y Año</label>
                        <input
                            className={`form-control ${errors.vehiculo && touched.vehiculo ? 'is-invalid' : ''}`}
                            type="text"
                            name="vehiculo"
                            placeholder="Ej: Toyota Corolla 2021"
                            onChange={handleChange}
                        />
                        {errors.vehiculo && touched.vehiculo && <div className="invalid-feedback">Error message for vehiculo.</div>}
                    </div>
                    {/* Nombre y Apellido */}
                    <div className="mb-2 form-group">
                        <label className=' fw-medium mb-2 ' htmlFor='hasta'>Nombre y apellido</label>
                        <input
                            className={`form-control mb-2 ${errors.nombreApellido && touched.nombreApellido ? 'is-invalid' : ''}`}
                            type="text"
                            name="nombreApellido"
                            placeholder="Ej: John Smith"
                            onChange={handleChange}
                            onBlur={() => setTouched({ ...touched, nombreApellido: true })}
                        />
                        {errors.nombreApellido && touched.nombreApellido && <div className="invalid-feedback">Este campo es obligatorio.</div>}
                    </div>
                </div>
            )}

            {currentStep === 3 && (
                // Step 3: Email and Teléfono
                <div>
                 {/* Email */}
                    <div className="mb-2 form-group">
                        <label className=' fw-medium mb-2 ' htmlFor='hasta'>Email</label>
                        <input
                            className={`form-control mb-2 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                            type="email"
                            name="email"
                            placeholder="Ej: usuario@ejemplo.com"
                            onChange={handleChange}
                            onBlur={() => setTouched({ ...touched, email: true })}
                        />
                        {errors.email && touched.email && <div className="invalid-feedback">Por favor, introduzca un email válido.</div>}
                    </div>

                    {/* Teléfono */}
                    <div className="mb-2 form-group">
                        <label className=' fw-medium mb-2 ' htmlFor='hasta'>Teléfono</label>
                        <input
                            className={`form-control mb-2 ${errors.telefono && touched.telefono ? 'is-invalid' : ''}`}
                            type="tel"
                            name="telefono"
                            placeholder="(555)-555-5555"
                            value={formData.telefono} // Bind input value to formData.telefono
                            onChange={handleChange}
                            onBlur={() => setTouched({ ...touched, telefono: true })}
                        />

                        {errors.telefono && touched.telefono && <div className="invalid-feedback">Por favor, introduzca un teléfono válido.</div>}
                    </div>
                        
                </div>
            )}

            {currentStep < totalSteps && (
                <button type="button" style={{backgroundColor: '#A7BF2E'}} className='w-100 border border-0 text-white fw-bold btn mt-4 p-2' onClick={nextStep} disabled={!isCurrentStepValid()}>
                    Continue
                </button>
            )}

            {currentStep === totalSteps && (
                <button type="submit"  style={{backgroundColor: '#A7BF2E'}} className='w-100 border border-0 text-white fw-bold btn mt-4 p-2' disabled={!isCurrentStepValid()}>
                    Solicitar cotización
                </button>
            )}
        </form>
    </Card>
    );
};

export default QuoteForm;
