import React from 'react';
import { Row, Col, Image, } from 'react-bootstrap';


const InformacionPage = () =>{

    const imagesWithText = [
        { src: 'images/servicios1.png', text: 'Transporte de motocicleta'},
        { src: 'images/servicios2.png', text: 'Transporte de autos de estudiantes' },
        { src: 'images/servicios3.png', text: 'Transporte de vehiculos de gran tamaño' },
        { src: 'images/servicios4.png', text: 'Transporte de botes y lanchas' },
        { src: 'images/servicios5.png', text: 'Transporte de autos de lujo' },
        { src: 'images/servicios6.png', text: 'Transporte de autos a otros estados' },
        { src: 'images/servicios7.png', text: 'Transporte de vehiculos nuevos' },
        { src: 'images/servicios8.png', text: 'Transporte de autos militares' },

      ];

      const firstColumnImages = imagesWithText.slice(0, 4); // Primeros 4 elementos
        const secondColumnImages = imagesWithText.slice(4, 8); // Siguientes 4 elementos


    return (
        <>
            <div >
            <Row>
                <Col md={6} xs={12}>
                    <h1 className='color-review aling-text-center my-5 ms-5'>NoManejes.com</h1>
                </Col>
                <Col md={6} xs={12}>
                    <p className='aling-text-end my-5 px-5'>NoManejes.com es una empresa del grupo Garlas LLC  y es la compañía de transporte de autos número 1 de los hispanos en USA, con más de 6 años de experiencia y una cabeza hispana a cargo que entiende tus necesidades. </p>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#FDECE7' }}>
        <Col xs={12} md={4} className='d-flex align-items-center justify-content-center'>
          <h1 className='color-review mt-5 px-5' style={{ fontSize: '4.5rem' }}>Nuestros Servicios</h1>
        </Col>

        <Col xs={12} md={4}>
          {firstColumnImages.map((item, index) => (
            <div key={index} className="mb-3 text-center">
              <Image src={item.src} alt={`Imagen ${index + 1}`} fluid />
              <h3 className='color-review'>{item.text}</h3>
            </div>
          ))}
        </Col>

        <Col xs={12} md={4}> 
          {secondColumnImages.map((item, index) => (
            <div key={index} className="mb-3 text-center">
              <Image src={item.src} alt={`Imagen ${index + 5}`} fluid />
              <h3 className='color-review'>{item.text}</h3>
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12} className='justify-content-center'>
        <h1 className='color-review px-5' style={{ fontSize: '4.5rem' }}>Ponte en contacto con nosotros</h1>
        <p className='px-5 color-review' style={{ fontSize: '1.5rem'}}>Explora nuestra sección de beneficios para descubrir por qué somos la elección preferida en el transporte de vehículos en Centroamérica.</p>
        <a  href="/tu-enlace-aqui"   className="ms-5 btn btn-primary bg-dark border-0 px-5 d-none d-md-block mt-3" style={{ width: '50%' }} role="button">
            Contactar al (1) 870-455-6742
        </a>
        <a href="/tu-enlace-aqui"className="btn btn-primary bg-dark border-0 d-block d-md-none mx-5" style={{ justifyContent: 'center', width: '75%' }}role="button">
          Contactar al (1) 870-455-6742
        </a>
        </Col>
        <Col md={6} xs={12} >
        </Col>
      </Row>
            </div>
        
        </>

    )
}

export default InformacionPage;