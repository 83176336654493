import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import QuoteForm from './FormQuote';

const Contactos = () => {
  return (
    <div className='my-5'>
      <Row className='d-flex align-items-center g-0' >
        <Col md={6} xs={12}>
          <h1 className='ms-5 mt-md-5 color-review mb-3'>Contactanos</h1>
          <h6 className='lead px-5'>Explora nuestra sección de beneficios para descubrir por qué somos la elección preferida en el transporte de vehículos en Centroamérica.</h6>
          <a  href="tel:+18704556742"   className="ms-5 btn btn-primary bg-dark border-0 px-5 d-none d-md-block mt-3" role="button">
              Contactar al (1) 870-455-6742
          </a>
          <a href="tel:+18704556742"className="btn btn-primary bg-dark border-0 d-block d-md-none mx-5 my-4" style={{ justifyContent: 'center', width: '75%' }}role="button">
            Contactar al (1) 870-455-6742
          </a>
        </Col>
        <Col md={6} xs={12} className='p-5'>
          <QuoteForm></QuoteForm>
        </Col>
      </Row>
        

   
    </div>
  )
}
export default Contactos;